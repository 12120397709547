import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

  static targets = []

  attempt_unlock(event) {
    event.preventDefault()

    let modal = document.getElementById(`unlockingModal_${event.currentTarget.dataset.sponsorId}`);
    modal.classList.toggle("hidden");
  }

  closeModal(event) {
    event.preventDefault()
    let modal = document.getElementById(`unlockingModal_${event.currentTarget.dataset.sponsorId}`);
    modal.classList.toggle("hidden");
  }
}