import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "filtersSection",
                     "modelThumbTemplate",
                     "modelsGrid",
                     "ninja",
                     "searchBar",
                     "spinner",
                     "sortButton",
                     "sortOptions",
                     "sortDirectionChevron",
                     "sortDirectionIcon",
                     "sortByTitle",
                     "filterByLetter",
                     "sortTimeSpanTitle",
                     "lettersOptions",
                    ]

  initialize(): void {
    this.host = `${window.location.protocol}//${window.location.host}/members`
    this.hotkeys = []
    this.initializeNinja()
    if(this.hasSortByTitleTarget) {
      this.initializeSortingLabel()
    }
    this.initializeLetterFiler()
    
  }

  initializeLetterFiler(){
    const queryParams = new URLSearchParams(window.location.search);
    const letter = queryParams.get("letter");
    if(letter){
      const letterBtn = document.querySelector(`span.letter-btn[data-value="${letter}"]`)
      letterBtn.removeAttribute("data-action");
      letterBtn.classList.remove("hover-letter-btn", "cursor-pointer")
      letterBtn.classList.add("active-filter-btn")
    }
  }

  initializeSortingLabel(){
    const queryParams = new URLSearchParams(window.location.search);
    const sort = queryParams.get("sort");
    switch (sort) {
      case "latest":
        this.sortByTitleTarget.innerHTML = "Release date"
        break;
      case "popular":
            this.sortByTitleTarget.innerHTML = "Most popular"
        break;
      case "viewed":
            this.sortByTitleTarget.innerHTML = "Most viewed"
        break;

      case "name":
        this.sortByTitleTarget.innerHTML = "Name"
        break;
      default:
        break;
    }
  }

  initializeNinja(): void {
    if (this.hasNinjaTarget) {
      this.ninjaTarget.addEventListener('change', (event) => {
        if(event.detail.search.length >= 3 ){
          this.search(event)
        }
      })
    } 
  }

  toggleSortMenu() {
    if (this.sortOptionsTarget.classList.contains("hidden")){
      this.sortOptionsTarget.classList.remove("hidden")
    } else {
      this.sortOptionsTarget.classList.add("hidden")
    }
  }

  toggleLettersFilterMenu(){
    if (this.lettersOptionsTarget.classList.contains("hidden")){
      this.lettersOptionsTarget.classList.remove("hidden")
    } else {
      this.lettersOptionsTarget.classList.add("hidden")
    }
  }
  

  sort_by(event) {
    this.spinnerTarget.classList.remove("hidden")
    const searchString = `${this.host}/models?sort=`
    const sortValue = event.currentTarget.dataset.modelsSortValue;

    window.location.href = `${searchString}${sortValue}`
  }

  togglefilters() {
    if (this.searchBarTarget.dataset.collapsed == "true"){
      this.searchBarTarget.dataset.collapsed = null
      this.filtersSectionTarget.innerText = "Hide options"
    }else {
      this.searchBarTarget.dataset.collapsed = "true"
      this.filtersSectionTarget.innerText = "Show options"
    }
    this.toggle_filters_section()
  }

  toggle_filters_section(){
    this.searchBarTarget.lastChild.classList.toggle("hidden")
    this.filtersSectionTarget.parentNode.firstChild.classList.toggle("fa-chevron-up")
    this.filtersSectionTarget.parentNode.firstChild.classList.toggle("fa-chevron-down")
  }

  open_search() : void {
    if(this.hasNinjaTarget){
      this.ninjaTarget.open()
    }else {
      document.querySelector('ninja-keys').open()
    }
  }

  search(event) : void {
    fetch(`${this.host}/models/search?query=${encodeURIComponent(event.detail.search)}` )
      .then(response => response.json())
      .then(response => {
        response.actors.forEach(actor => {
          
          const page = {
            id: `Model${actor.name}`,
            title: `${actor.name}`,
            section: 'Models',
            handler: () => {
              window.location.href = `${this.host}/models/${actor.cached_slug}`;
            },
          };

          if (actor.thumb_url != "") {
            page.icon = `<img style="margin-right: 1rem" src="${actor.thumb_url}" width="38" height="55" />`;
          }
          if (!this.hotkeys.map(r => r.id).includes(`Model${actor.name}`)) {
            this.hotkeys.push(page)
          }
        });

        response.releases.forEach(release => {
          const page = {
            id: `Release${release.title}`,
            title: `${release.title}`,
            icon: `<img style="margin-right: 1rem" src="${release.thumb_url}" width="80" height="55" />`,
            section: 'Releases',
            handler: ()=> {
              window.location.href = `${this.host}/video/${release.cached_slug}`;
            },
          };
          if (!this.hotkeys.map(r=> r.id).includes(`Release${release.title}`)) {
            this.hotkeys.push(page)
          }
        });
        this.ninjaTarget.data = this.hotkeys;
      })
      .catch(err => console.log(err))
  }

  letter(event) {
    this.spinnerTarget.classList.remove("hidden")
    const searchString = `${this.host}/models?letter=`
    const filterLetter = event.currentTarget.dataset.value;
    window.location.href = `${searchString}${encodeURIComponent(filterLetter)}`
  }

  closeOverlay(){
    [this.sortOptionsTarget, this.lettersOptionsTarget].forEach((target)=>{
      if (!target.classList.contains("hidden")){
        target.classList.add("hidden")
      }
    });
  }
}
