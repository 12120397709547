import { Controller } from "@hotwired/stimulus";
import Plyr from "plyr";
import Hoover from "../hoover";

export default class extends Controller {
  static targets = ["trailerPlayer"];

  connect() {
    this.element[this.identifier] = this;
    const players = document.querySelectorAll(".player");
    const watched = new Array(0);
    const viewed = new Array(0);

    if (typeof Plyr !== "undefined") {
      for (var i = 0; i < players.length; i++) {
        const isVR = players[i].getAttribute("vr-enabled") === "true";
        const plyr = new Plyr(players[i], {});
        let play_counted = false;

        plyr.on("ready", () => {
          //TrailerPreviews.show_view_count();
        });

        plyr.on("click", () => {
          if (isVR) {
            plyr.pause();
            window.location.href = "/join";
            return;
          }
        });

        plyr.on("playing", () => {
          Hoover.event("video:play", this.element.dataset.id);
          if (play_counted === false) {
            //TrailerPreviews.record_view();
            this.recordView(this.element.dataset.id, this.element.dataset.rId);
            play_counted = true;
          }

          if (viewed.includes(this.element.dataset.id)) {
          } else {
            viewed.push(this.element.dataset.id);
            // TODO: only do this when in trial or expired mode

            const controller =
              this.application.getControllerForElementAndIdentifier(
                document.getElementById("trailer_player"),
                "trailer",
              );
            if (controller) {
              controller.checkPreviewCount();
            }
          }
        });

        plyr.on("timeupdate", () => {
          watched[parseInt(plyr.currentTime)] = 1;
          var sum = watched.reduce(function (acc, val) {
            return acc + val;
          }, 0);

          if (sum % 10 == 0) {
            Hoover.event("video:watched:seconds", this.element.dataset.id);
          }
        });
      }
    }
  }

  recordView(id, rId) {
    const params = { type: "VideoPlay", r_id: rId };

    fetch(`/api/videos/${id}/events`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  }
}
