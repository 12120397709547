import { Controller } from "@hotwired/stimulus";
import { useMember } from "./mixins/useMember";

export default class extends Controller {
  static targets = [ "" ]

  initialize(): void {
    useMember(this)
    this.setupMember()
    this.setupServerMember()
  }

  setupServerMember(){
    fetch(
      `/api/members/${this.currentMember.id}`, {
      method: "GET",
      cache: "no-cache",
      headers: {"Content-Type": "application/json"},
    }).then(member => member.json())
    .then(member =>  this.storeValues(member))
  }

  storeValues(result){ 
    this.element.dataset.member_name = result.name
    this.element.dataset.member_username = result.username
    this.element.dataset.member_email = result.email
    this.element.dataset.member_status = result.status
    this.element.dataset.member_biller_account = result.biller_account
    this.element.dataset.member_trial_active = result.trial_active
  }
}
