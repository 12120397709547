import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hover", "media" ]

  initialize() {
    // Using this to keep track of what video thumbnail is active
    // so we can turn it off when the next one starts.
    window.activeVideoThumbnail = null

    this.clickTime = 0
  }

  connect() {
    this.hoverTarget.addEventListener("touchstart", (e) => {
      this.showMedia();
    })

    this.hoverTarget.addEventListener("touchcancel", (e) => {
      this.hideMedia();
    })

    this.hoverTarget.addEventListener("mouseover", () => {
      this.showMedia();
    })

    this.hoverTarget.addEventListener("mouseout", () => {
      this.hideMedia();
    })
  }

  showMedia() {
    let elems = this.hoverTarget.querySelectorAll(".gradient, .video-thumbnail-media-overlay")
	  for (var i = 0; i < elems.length; i++) {
      elems[i].style.display = 'none'
    }

    this.mediaTarget.disablePictureInPicture = true;
    this.mediaTarget.play()

    if (window.activeVideoThumbnail) {
      window.activeVideoThumbnail.hideMedia()
    }

    window.activeVideoThumbnail = this

    let date = new Date()
    this.clickTime = date.getTime()
  }

  hideMedia() {
    let elems = this.hoverTarget.querySelectorAll(".gradient, .video-thumbnail-media-overlay")
	  for (var i = 0; elems != null && i < elems.length; i++) {
      elems[i].style.display = 'flex'
    }

    this.mediaTarget.load()

    let date = new Date()
    let drift = date.getTime() - this.clickTime
    let maxDrift = 50;

    this.clickTime = 0

    if (window.isMobile && drift < maxDrift) {
      this.hoverTarget.click()
    }
  }
}
