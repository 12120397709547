import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  declare readonly buttonTarget: HTMLElement
  declare readonly buttonTargets: HTMLElement[]
  declare readonly hasButtonTargets: boolean

  public send(_event: Event): void {
    this.postOneClick(this.element)
  }

  protected postOneClick(element: HTMLElement) {
    const path = element.dataset.path
    const option = element.dataset.option
    const metadata = element.dataset.metadata
    const spinner = this.createSpinner()

    fetch(path, {
      method: "POST",
      cache: "no-cache",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({option: option, metadata: metadata})
    }).then(res => res.json()).then(res => {
      if(res.error) {
        spinner.parentNode.removeChild(spinner)
        alert(res.error)
        return
      }

      if (res.redirect && res.redirect.length > 0) {
        window.location.href = res.redirect;
      } else {
        window.location.href = "/members";
      }
    })
  }

  private createSpinner(): HTMLElement {
    let spinWrap = document.createElement("div")
    Object.assign(spinWrap, {className: "fixed inset-0 flex flex-row items-center justify-center p-20 space-x-4 bg-black bg-opacity-90 text-white whitespace-nowrap lg:text-xl z-50"})

    let spinner = document.createElement("i")
    Object.assign(spinner, {className: "fas fa-spinner animate-spin"})
    spinWrap.appendChild(spinner)

    let text = document.createElement("span")
    text.innerHTML = "Processing Transaction"
    spinWrap.appendChild(text)

    document.body.appendChild(spinWrap)
    return spinWrap
  }
}


