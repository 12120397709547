import { Controller } from "@hotwired/stimulus"
import { useMember } from "./mixins/useMember";

export default class extends Controller {
  static targets = ["wrapper", "errorMessage", "characterCount", "textBody", "submitButton"]
  static values = {
    rid: String
  }

  initialize() {
    useMember(this)
    this.setupMember()
    this.maxCharacterCount = 500
  }

  connect() {

  }


  updateCount() {
    let length = this.textBodyTarget.value.length
    this.characterCountTarget.innerText = this.maxCharacterCount - length

    if (length > this.maxCharacterCount) {
      this.textBodyTarget.value = this.textBodyTarget.value.substr(0, 500)
      this.characterCountTarget.classList.add("text-red-500")
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add("error")
      this.submitButtonTarget.value = "Comment is too long"
    } else {
      this.characterCountTarget.classList.remove("text-red-500")
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove("error")
      this.submitButtonTarget.value = "Post Comment"
    }
  }

  submit(e) {
    e.preventDefault()

    let url = `/api${this.submitButtonTarget.parentElement.getAttribute("action")}`
    let comment = this.textBodyTarget.value
    let params = {
      comments: {
        comment: comment.trim(),
        video_release_id: this.ridValue
      }
    }

    this.submitButtonTarget.classList.add("disabled")
    this.submitButtonTarget.value = "Posting Comment..."

    fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    }).then(res => res.json()).then(res => {
      this.submitButtonTarget.classList.remove("disabled")
      this.submitButtonTarget.value = "Post Comment"

      if (res.error) {
        this.submitButtonTarget.classList.add("error")
        this.errorMessageTarget.innerText = res.error
      } else {
        this.errorMessageTarget.innerText = null
        this.loadComments()
      }
    })
  }

  upvote(_event) {
    const upBtn = _event.currentTarget
    const id = upBtn.dataset.id
    const counter = upBtn.nextSibling
    this.submitVote(id, 1)
      .then(value => {
        counter.innerText = value
      })
      .catch(err => {
        alert("You've already upvoted this comment")
        console.error(err)
      })
  }

  downvote(_event) {
    const dnBtn = _event.currentTarget
    const id = dnBtn.dataset.id
    const counter = dnBtn.previousSibling
    this.submitVote(id, -1)
      .then(value => {
        counter.innerText = value
      })
      .catch(err => {
        alert("You've already downvoted this comment")
        console.error(err)
      })
  }

  submitVote(id, value) {
    const url = `/api/videos/${this.ridVaue}/comments/${id}/${this.currentMember.id}`
    const params = { value: value }
    return fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    }).then(res => res.json())
      .then(res => (res.error ? Promise.reject(res.error) : res.value))
  }
}
