import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { target: String }

  launch(event) {
    let modal = document.getElementById(this.targetValue);
    if (modal) {
      event.preventDefault();
      modal.classList.toggle("hidden");
    }

  }

  close(event) {
    let modal = document.getElementById(this.targetValue);
    if (modal) {
      modal.classList.add("hidden");
    }
  }

  escClose(event) {
    this.close(event)
  }
}