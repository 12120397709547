import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hover", "media" ]

  initialize() {
    this.active = false
    this.clickTime = 0
  }

  connect() {
    this.hoverTarget.addEventListener("touchstart", () => {
      this.hideMedia()
    })

    this.hoverTarget.addEventListener("touchend", () => {
      this.hideMedia()
    })

    this.hoverTarget.addEventListener("touchcancel", () => {
      this.hideMedia()
    })

    this.hoverTarget.addEventListener("mouseover", () => {
      this.showMedia()
    })

    this.mediaTarget.addEventListener("mouseout", () => {
      this.hideMedia()
    })
  }

  showMedia() {
    if (!this.active) {
      this.hoverTarget.style.display = "none"
    }

    let date = new Date()

    this.active = true
    this.clickTime = date.getTime()
  }

  hideMedia() {
    if (this.active) {
      this.hoverTarget.style.display = "block"
    }

    let date = new Date()
    let drift = date.getTime() - this.clickTime
    let maxDrift = 50;

    this.active = false
    this.clickTime = 0

    if (window.isMobile && drift < maxDrift) {
      this.hoverTarget.click()
    }
  }
}
