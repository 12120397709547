import { Controller } from "@hotwired/stimulus"
import stan from "stan"

export default class extends Controller<HTMLDivElement> {
  static targets = [ "oneclickImage", "oneclickVideo" ]

  declare readonly hasOneclickImageTarget: boolean
  declare readonly oneclickImageTarget: HTMLImageElement
  declare readonly oneclickImageTargets: HTMLImageElement[]

  declare readonly hasOneclickVideoTarget: boolean
  declare readonly oneclickVideoTarget: HTMLVideoElement
  declare readonly oneclickVideoTargets: HTMLVideoElement[]

  connect() {
    // Images and Videos are going to be cached in the static HTML returned from cloudfront, so we
    // need to grab the src from the data-src attribute and set it to the src attribute.
    this.oneclickImageTargets.forEach((image) => {
        image.src = image.dataset.src
        this.createClickHandler(image)
        this.createObserver(image)
    });

    this.oneclickVideoTargets.forEach((video) => {
        // For videos we need to set the src attribute on the source element.
        video.querySelector("source").src = video.dataset.src
        this.createClickHandler(video)
        this.createObserver(video)
    });
  }

  sendImpressionEvent(target: HTMLElement) {
    const el = this.element.firstChild as HTMLElement;
    const option = el.dataset.option;
    stan.event("oneclick.impression", option);
  }
  
  private createClickHandler(target: HTMLElement) {
    const el = this.element.firstChild as HTMLElement;
    const option = el.dataset.option;
    target.addEventListener("click", () => {
      stan.event("oneclick.click", option);
    })
  }

  private createObserver(target: HTMLElement) {
    let observer: IntersectionObserver
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.sendImpressionEvent(target)
          observer.unobserve(target)
        }
      })
    });

    observer.observe(target);
  }
}