import OneclickController from "./oneclick_controller"

export default class extends OneclickController {

  declare readonly upgradeOptionValue: String

  connect() {
    this.upgradeOptionValue = "quarterly"
  }

  public selectUpgradeOption(event) {
    this.upgradeOptionValue = event.target.value
  }

  public send(_event: Event): void {
    const oneclickElement = this.buttonTarget.parentElement as HTMLElement
    oneclickElement.dataset.option = this.upgradeOptionValue;
    this.postOneClick(oneclickElement);
  }
}
