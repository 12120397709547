import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hover", "media", "multiImages", "previewImage"]

  initialize() {
    this.active = false
    this.currentImageIndex = null
    this.images = this.multiImagesTarget.getElementsByTagName("img")
    this.hoverAreas = this.mediaTarget.getElementsByTagName("div")
    this.clickTime = 0
  }

  connect() {
    for (var i = 0; i < this.hoverAreas.length; i++) {
      this.hoverAreas[i].addEventListener("mouseover", (e) => {
        let index = e.target.dataset.index
        let activeHoverArea = e.target
        let activeHoverAreaStatusBar = activeHoverArea.querySelector("span")

        if (this.currentImageIndex != index) {
          for (var i = 0; i < this.hoverAreas.length; i++) {
            let hoverArea = this.hoverAreas[i]
            let statusBar = hoverArea.querySelector("span")

            statusBar.classList.remove("active")
          }

          activeHoverAreaStatusBar.classList.add("active")
          this.previewImageTarget.src = this.images[index].dataset.src
        }

        this.currentImageIndex = index
      })
    }

    this.hoverTarget.addEventListener("touchstart", () => {
      if (!this.active) {
        this.hoverTarget.querySelectorAll("div").forEach((e) => {
          e.style.display= "none"
        })
      }

      let date = new Date()

      this.active = true
      this.clickTime = date.getTime()
    })

    this.hoverTarget.addEventListener("touchend", () => {
      if (this.active) {
        this.hoverTarget.querySelectorAll("div").forEach((e) => {
          e.style.display= "flex"
        })
      }

      let date = new Date()
      let drift = date.getTime() - this.clickTime
      let maxDrift = 50;

      this.active = false
      this.clickTime = 0

      if (window.isMobile && drift < maxDrift) {
        this.hoverTarget.click()
      }
    })

    this.hoverTarget.addEventListener("mouseover", () => {
      if (!this.active) {
        this.hoverTarget.style.display = "none"
        this.mediaTarget.style.display = "flex"
      }

      this.active = true
    })

    this.mediaTarget.addEventListener("mouseout", () => {
      if (this.active) {
        this.hoverTarget.style.display = "block"
        this.mediaTarget.style.display = "none"
        this.mediaTarget.src = null
        this.currentImageIndex = null

        for (var i = 0; i < this.hoverAreas.length; i++) {
          let hoverArea = this.hoverAreas[i]
          let statusBar = hoverArea.querySelector("span")

          statusBar.classList.remove("active")
        }
      }

      let date = new Date()
      let drift = date.getTime() - this.clickTime
      let maxDrift = 50;

      this.active = false
      this.clickTime = 0

      if (window.isMobile && drift < maxDrift) {
        this.hoverTarget.click()
      }
    })
  }
}
