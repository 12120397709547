import { Controller } from "@hotwired/stimulus";
import { useMember } from "./mixins/useMember";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

export default class extends Controller {
  static targets = ["container"];

  declare readonly hasContainerTarget: boolean;
  declare readonly containerTarget: HTMLInputElement;
  declare readonly containerTargets: HTMLInputElement[];

  initialize() {
    useMember(this);
    this.setupMember();
  }

  connect() {
    fetch(`/api/members/${this.currentMember.id}/deals`, {
      cache: "no-store",
    }).then((res) => {
      if (res.status === 200) {
        res.text().then((html) => {
          this.containerTarget.innerHTML = html;
          new Swiper(".deal-carousel", {
            speed: 400,
            loop: true,
            autoplay: {
              delay: 3500,
              disableOnInteraction: false,
            },
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
              dynamicBullets: true,
            },
          });
        });
      }
    });
  }
}
