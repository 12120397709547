import { Controller } from "@hotwired/stimulus"
import Mustache from "mustache"

export default class extends Controller {
  static targets = [ ]
  
  initialize() {
    var data_element = document.getElementById("featured-video-player-data");
    
    if (typeof(data_element) != "undefined" && data_element != null) {
      var featured_videos = JSON.parse(document.getElementById("featured-video-player-data").textContent)
      var featured_video = featured_videos[Math.floor(Math.random() * featured_videos.length)]
      var featured_video_player_template = document.getElementById("featured-video-player-template").textContent
      var rendered_template = Mustache.render(featured_video_player_template, featured_video)

      document.getElementById("featured-video-player").innerHTML = rendered_template
    }
  }

  video_banner_redirect(event) {
    if (event.currentTarget.dataset.siteTrailer == "true") {
      window.location.href = event.currentTarget.dataset.url
    } else {
      window.location.href = "/join"
    }
  }
}


