import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filtersSection",
    "modelThumbTemplate",
    "releasesGrid",
    "ninja",
    "searchBar",
    "spinner",
    "sortButton",
    "sortOptions",
    "sortDirectionChevron",
    "sortDirectionIcon",
    "sortByTitle",
    "sortTimeSpanTitle"
  ]

  initialize(): void {
    this.host = `${window.location.protocol}//${window.location.host}/members`
    
    this.initializeFilterLabel()
  }

  initializeFilterLabel() {
    let queryParams = new URLSearchParams(window.location.search);
    let sort = queryParams.get("sort");
    switch (sort) {
      case "latest":
        this.sortByTitleTarget.innerHTML = "Release date"
        break;
      case "popular":
        this.sortByTitleTarget.innerHTML = "Most popular"
        break;
      case "viewed":
        this.sortByTitleTarget.innerHTML = "Most viewed"
        break;

      case "name":
        this.sortByTitleTarget.innerHTML = "Name"
        break;
      default:
        break;
    }
  }

  toggleSortMenu() {
    if (this.sortOptionsTarget.classList.contains("hidden")) {
      this.sortOptionsTarget.classList.remove("hidden")
    } else {
      this.sortOptionsTarget.classList.add("hidden")
    }
  }

  sort_by(event) {
    this.spinnerTarget.classList.remove("hidden")
    const searchString = `${this.host}/videos?sort=`
    const sortValue = event.currentTarget.dataset.releasesSortValue;

    window.location.href = `${searchString}${sortValue}`
  }

  togglefilters() {
    if (this.searchBarTarget.dataset.collapsed == "true") {
      this.searchBarTarget.dataset.collapsed = null
      this.filtersSectionTarget.innerText = "Hide options"
    } else {
      this.searchBarTarget.dataset.collapsed = "true"
      this.filtersSectionTarget.innerText = "Show options"
    }
    this.toggle_filters_section()
  }

  toggle_filters_section() {
    this.searchBarTarget.lastChild.classList.toggle("hidden")
    this.filtersSectionTarget.parentNode.firstChild.classList.toggle("fa-chevron-up")
    this.filtersSectionTarget.parentNode.firstChild.classList.toggle("fa-chevron-down")
  }
  
  escClose() {
    if (!this.sortOptionsTarget.classList.contains("hidden")) {
      this.sortOptionsTarget.classList.add("hidden")
    }
  }
}