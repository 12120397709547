import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
      // If the box has already been closed, then keep it closed
      if (document.cookie.includes("paypal=closed")) {
        this.close();
      }
    }

    close() {
      const cookie = "paypal=closed; path=/; max-age=31536000";
      document.cookie = cookie;
      this.element.classList.add("hidden");
    }
}