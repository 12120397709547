import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "supportLinks" ]

  declare readonly supportLinksTarget!: HTMLElement
  declare readonly supportLinksTargets!: HTMLElement[]
  declare readonly hasSupportLinksTarget!: boolean

  toggleMenu(event: Event): void {
    event.preventDefault();
    this.supportLinksTarget.classList.toggle('hidden')
  }
}
