import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count"]

  initialize() {
    this.setTitleCount(this.retrievePreviewsCookieValue())
  }

  setTitleCount(count) {
    if (count < 0) {
      count = 0
    }
    this.countTarget.innerText = `You have ${count} previews left for today.`
  }

  retrievePreviewsCookieValue() {
    this.cookie = "prs";
    if (document.cookie.split(';').some((item) => item.trim().startsWith(`${this.cookie}=`))) {
      const value = document.cookie.split('; ').find(row => row.startsWith(`${this.cookie}=`)).split('=')[1]
      return parseInt(value)
    } else {
      document.cookie = `${this.cookie}=5;max-age=86400;`
      return 5;
    }
  }

  checkPreviewCount() {
    const count = this.retrievePreviewsCookieValue()
    if (count <= 0) {
      this.disableVideoPlayback()
    }

    document.cookie = `${this.cookie}=${count - 1};max-age=86400`
    this.setTitleCount(this.retrievePreviewsCookieValue())
  }

  disableVideoPlayback() {
    this.countTarget.innerText = "You have 0 free previews for today."

    document.getElementById("player-wrapper").remove()
    document.getElementById("no-player-wrapper").classList.remove("hidden")
  }
}
