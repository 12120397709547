import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        // Check if the disclaimer has been accepted, if not show it
        if (!document.cookie.includes("disclaimer=accepted")) {
            this.open();
        }
    }

    close() {
        this.element.classList.add("hidden");
    }

    open() {
        this.element.classList.remove("hidden");
    }

    accept() {
        // The terms have been accepted, add a cookie so we don't show the disclaimer again
        const cookie = "disclaimer=accepted; path=/; max-age=31536000";
        document.cookie = cookie;
    }

    acceptAndClose() {
        this.close();
        this.accept();
    }

    leave() {
        window.location.href = "https://www.google.com";
    }
}
