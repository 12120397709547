import OneclickController from "./oneclick_controller"
import Cookie from "js-cookie"
import Hoover from "../hoover"

export default class extends OneclickController {
  static targets = [ "confirmation" ]

  declare readonly confirmationTarget: HTMLInputElement
  declare readonly confirmationTargets: HTMLInputElement[]
  declare readonly hasConfirmationTargets: boolean

  connect() {
    if (Cookie.get("tcheck")) {
      this.confirmationTarget.checked = true
    }
  }

  send(_event: Event): void { 
    Cookie.set("tcheck", true, {expires: 30})

    if (this.confirmationTarget.checked) {
      Hoover.event('1click:trial_upgrade:click', 'confirmation');
      const oneclickElement = this.buttonTarget.parentElement as HTMLElement
      this.postOneClick(oneclickElement)
    } else {
      window.location.href = "/members"
    }
  }
}
