import { Controller } from "@hotwired/stimulus"
import { useMember } from "./mixins/useMember"
import StarRating from "star-rating.js"

export default class extends Controller {
  static values = {
    rid: String
  }

  initialize() {
    useMember(this)
    this.setupMember()
    this.setupRatings()
  }

  setupRatings() {
    fetch(`/api/videos/${this.ridValue}/ratings/${this.currentMember.id}`)
      .then(res => res.json())
      .then(data => {
        var option = this.element.querySelector(`option:nth-child(${data.rating})`)
        if (option) {
          option.setAttribute("selected", true)
        }

        this.element.value = data.rating
        this.element.dispatchEvent(new Event("change"))
      })

    new StarRating(this.element, {tooltip: false})

    this.element.addEventListener("change", () => {
      const url = `/api/videos/${this.ridValue}/ratings`
      const newValue = parseInt(this.element.value);
      if (!isNaN(newValue)) {
        const params = {value: newValue}

        fetch(url, {
          method: "POST",
          cache: "no-cache",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(params)
        }).then((res) => res.json()).then(res => {
          if (res.error) {
            alert(res.error)
            return
          } else {
            this.element.value = res.rating
          }
        })
      }
    })
  }
}
