import { Controller } from "@hotwired/stimulus"
import { useMember } from "./mixins/useMember";
import Cookies from "js-cookie"

export default class extends Controller {
  static targets = []

  connect() {
    this.container = this.element
    this.mid = Cookies.get("i")
    this.params = this._buildParams()
    this._loadCams()
  }

  _buildParams() {
    let mode = this.container.dataset.mode || null
    let count = this.container.dataset.count || null
    let params = {}
    if(mode) params.mode = mode
    if(count) params.count = count
    let queryString = new URLSearchParams(params)
    return queryString.toString()
  }

  _loadCams() {
    fetch(`/api/livecams/${this.mid}${this.params ? "?" + this.params : ""}`, {cache: "no-store"})
      .then(res => {
        if (res.status === 200) {
          res.text().then(html => {
            this.container.innerHTML = html
            fetch(`/api/members/cams?i=${this.mid}`)
              .then(response => response.json())
              .then(data => {
                let root_url = data.streammate_urls.root
                if (root_url) {
                  document.querySelectorAll(".livecam > .livecam-frame").forEach((camlink)=> {
                    camlink.href = root_url + "&repage=cam&reargs=" + camlink.dataset.performer
                  })

                  document.querySelectorAll("#livecam-box .cam-category").forEach((camlink)=> {
                    let cat = camlink.href.substr(camlink.href.lastIndexOf("/") + 1)
                    camlink.href = root_url + "&repage=category&reargs=" + cat
                  })
                }

                if(this.container.dataset.mode === "video") {
                  document.querySelectorAll("#livecam-banner video").forEach((vid)=> vid.play())
                }

                if (data.cam_urls) {
                  const fffframe = document.querySelector("#flirt-for-free > iframe")
                  if (fffframe) {
                    fffframe.src = data.cam_urls.flirt_for_free
                  }
                }
              })
          })
        }
      })
  }
}
