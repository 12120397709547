export const useMember = (controller) => {
  Object.assign(controller, {
    setupMember() {
      this._parseCookies();

      let mid = this._findMid();
      this.currentMember = null;

      if (mid) {
        this.currentMember = {
          id: this._findMid(),
          username: this._findUsername(),
        };
      }
    },

    loggedIn() {
      return !!this.mid;
    },

    loggedOut() {
      return !this.loggedIn();
    },

    _parseCookies() {
      this._cookies = document.cookie.split(";").map((c) => c.split("="));
    },

    _findMid() {
      let match = this._cookies.filter((a) => a[0].trim() == "i");
      if (match && match[0]) {
        return match[0][1];
      }
    },

    _findUsername() {
      let match = this._cookies.filter((a) => a[0].trim() == "username");
      if (match && match[0]) {
        return match[0][1];
      }
    },
  });
};
