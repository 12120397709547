import { Controller } from "@hotwired/stimulus"
import { useMember } from "./mixins/useMember";

export default class extends Controller {
  static targets = [ "emptyMsg"]
  static values = {
    rid: String
  }

  initialize() {
    useMember(this)
    this.setupMember()
  }

  removeFavorite(event) {
    this.submitReaction(event)
  }

  submitReaction(event, kind = "love") {
    fetch(
      `/api/videos/${event.currentTarget.dataset.release}/reactions/${this.currentMember.id}?kind=${kind}`, {
      method: "DELETE",
      cache: "no-cache",
      headers: {"Content-Type": "application/json"},
    }).then(res => res.json())
    .then(res => console.log(res))
    if(event.currentTarget.parentNode.parentNode.parentNode.parentNode.childElementCount == 1){
      this.emptyMsgTarget.classList.toggle("hidden")
    }
    event.currentTarget.parentNode.parentNode.parentNode.parentNode.remove()
  }
}
