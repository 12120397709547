import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "sponsorsSection", "exclusiveSponsorsSection", "exclsiveButton", "allButton"  ]

  switchSection(event) {
    if(event.target.dataset.sponsorsValue === 'all') {
      this.exclusiveSponsorsSectionTarget.classList.add("hidden")
      this.sponsorsSectionTarget.classList.remove("hidden")
    } else {
      this.exclusiveSponsorsSectionTarget.classList.remove("hidden")
      this.sponsorsSectionTarget.classList.add("hidden")
    }
    this.switchButtonStyles()
  }

  switchButtonStyles(){
    var classList1 = this.exclsiveButtonTarget.className
    var classList2 = this.allButtonTarget.className
    this.allButtonTarget.className = ''
    this.exclsiveButtonTarget.className = ''
    this.allButtonTarget.classList = classList1
    this.exclsiveButtonTarget.classList = classList2
  }
  
}