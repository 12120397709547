import { Controller } from "@hotwired/stimulus"
import { useMember } from "./mixins/useMember";

export default class extends Controller {
  static targets = [
    "username",
    "desktopMenu",
    "mobileMenu",
    "desktopLivecams",
    "mobileLivecams",
    "desktopCoinsMenu",
  ]

  initialize() {
    useMember(this)
    this.setupMember()
  }

  connect() {
    if (this.hasUsernameTarget && this.currentMember) {
      this.usernameTarget.innerHTML = this.currentMember.username
    }
    this.initializeLiveCams()
  }

  openDesktopMenu() {
    this.desktopMenuTarget.classList.toggle("hidden")
  }

  openDesktopCoinsMenu() {
    this.desktopCoinsMenuTarget.classList.toggle("hidden")
  }

  closeDesktopCoinsMenu() {
    this.desktopCoinsMenuTarget.classList.toggle("hidden")
  }

  closeDesktopMenu() {
    this.desktopMenuTarget.classList.toggle("hidden")
  }

  closeMobileMenu() {
    this.mobileMenuTarget.classList.toggle("hidden")
  }

  openMobileMenu() {
    this.mobileMenuTarget.classList.toggle("hidden")
  }

  openDesktopLivecams() {
    this.desktopLivecamsTarget.classList.toggle("hidden")
  }

  openMobileLivecams() {
    this.mobileLivecamsTarget.classList.toggle("hidden")
  }

  closeNotification(event) {
    const element = document.getElementById(`notification_${event.currentTarget.dataset.siteNavigationIdValue}`)
    this.closeDesktopMenu();
    const idValue = event.currentTarget.dataset.siteNavigationIdValue
    if (idValue != "empty") {
      this.markNotificationAsRead(idValue)
    }
    element.remove()
    this.checkCloseOverlay();
    event.stopPropagation();
  }

  markNotificationAsRead(notificationId) {
    const params = {
      notificationId: notificationId,
    };

    fetch('/api/members/read_notification/', {
      method: "POST",
      cache: "no-cache",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(response => {
        console.log(response)
      });
  }

  notificationsIndexRedirect(event) {
    this.markNotificationAsRead(event.currentTarget.dataset.siteNavigationIdValue)
    window.location.href = `${event.currentTarget.dataset.siteNavigationIndexPathValue}`
  }

  checkCloseOverlay() {
    const notificationArray = Array.from(document.getElementsByClassName("notification-bubble"))
    if (notificationArray.length == 0) {
      this.closeDesktopMenu();
      const emptyBubbleNotification = document.getElementById("notification_empty")
      emptyBubbleNotification.classList.toggle("hidden");
    }
  }

  initializeLiveCams() {
    if (document.querySelectorAll(".livecams").length) {
      fetch("/api/members/cams")
        .then((response) => response.json())
        .then((data) => {
          if (data.streammate_urls) {
            document.querySelector("#livecams_url").innerHTML = data.streammate_urls.root
            document
              .querySelectorAll('a[href="#streamate"]')
              .forEach((anchor) => {
                anchor.href = data.streammate_urls.root;
                if (anchor.children.length > 0) {
                  // Only show images when the link is ready 
                  anchor.children[0].classList.remove("hidden")
                }
              });
          }
        });
    }
  }

  redirectFavorites(event) {
    this.host = `${window.location.protocol}//${window.location.host}`
    window.location.href = `${this.host}${event.currentTarget.dataset.siteNavigationFavoritesUrlValue}`
  }

  redirectSubscriptions(event) {
    this.host = `${window.location.protocol}//${window.location.host}`
    window.location.href = `${this.host}${event.currentTarget.dataset.siteNavigationSubscriptionsUrlValue}`
  }
}
