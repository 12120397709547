/* eslint no-console:0 */

import '@fortawesome/pro-light-svg-icons';
import '@fortawesome/pro-regular-svg-icons';
import '@fortawesome/pro-solid-svg-icons';

import.meta.glob(['../images/**/*'])
import 'ninja-keys'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import LazyLoad from "vanilla-lazyload"
import Rails from "@rails/ujs"
import 'htmx.org'
import 'htmx.org/dist/ext/path-deps.js'
import Alpine from 'alpinejs'
Rails // if we remove this, logout link breaks due to Vite tree shaking

Alpine.start()

new LazyLoad()

declare global {
  interface Window { isMobile: boolean; }
}

window.isMobile = navigator.userAgent.toLowerCase().match(/mobile/i) !== null

const swiperOptions = {
  actor: {
    modules: [Navigation, Pagination],
    slidesPerView: 2,
    spaceBetween: 4,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 4,
      },
    },
  },
  series: {
    modules: [Navigation, Pagination],
    slidesPerView: 2,
    spaceBetween: 4,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 4,
      },
    },
  },
  video: {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 4,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 4,
      },
    },
  },
  adds: {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 2,
    spaceBetween: 4,
    speed: 400,
    loop: true,
    autoplay: {
      delay: 4500,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 4,
      },
    },
  },
  billboard: {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 4,
    speed: 400,
    loop: true,
    autoplay: {
      delay: 4500,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 4,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 4,
      },
    },
  },
  releases: {
    modules: [Navigation, Pagination, Autoplay],
    speed: 400,
    loop: true,
    autoplay: {
      delay: 4500,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  },
  networkSites: {
    modules: [Autoplay, Pagination],
    speed: 200,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      pauseOnMouseEnter: true,
      delay: 4500,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      1536: {
        slidesPerView: 6,
        spaceBetween: 30,
      },
    },
  },
};

new Swiper(`.actor-carousel`, swiperOptions.actor);
new Swiper(`.series-carousel`, swiperOptions.series);
new Swiper(`.video-carousel`, swiperOptions.video);
new Swiper(`.adds-carousel`, swiperOptions.adds);
new Swiper(`.billboard-carousel`, swiperOptions.billboard);
new Swiper(`.releases-carousel`, swiperOptions.releases);

document.addEventListener('htmx:afterRequest', function (evt) {
  if (evt.detail.pathInfo.requestPath == "/api/sites/network") {
    new Swiper(`.sites-images`, swiperOptions.networkSites);
  }
});

// TODO: This code doesn't work but the above does...
// for (const [containerClass, options] of Object.entries(swiperOptions)) {
//   console.log({ containerClass });
//   const carousel = new Swiper(`.${containerClass}-carousel`, options);
//   if (carousel && carousel.slides.length <= 3) {
//     // Disable autoplay if there is only one slide
//     // carousel.autoplay.stop();
//   }
// }

// // If on tour && mobile, all video previews go to /join
// if (window.stan && window.stan.device.isMobile() && window.location.pathname.indexOf("/members") !== 0) {
//   var elements = document.getElementsByTagName("a");
//   for (var i = 0, len = elements.length; i < len; i++) {
//     elements[i].onclick = function(e) {
//       if (e.currentTarget.href.indexOf("/video/") !== -1) {
//         e.preventDefault();
//         window.location.href = "/join";
//       }
//     }
//   }
// }
// 
// // If in ma adjust video links
// // TODO: This should be fixed in code and removed from the javascript side.
// if (window.location.pathname.indexOf("/members") == 0) {
//   var elements = document.getElementsByTagName("a");
//   for (var i = 0, len = elements.length; i < len; i++) {
//     elements[i].onclick = function(e) {
//       if (!url.includes("/members") && url.includes("/video")) {
//         e.preventDefault();
//         var url = e.currentTarget.href;
//         window.location.href = "/members" + (new URL(e.currentTarget.href).pathname);
//       }
//     }
//   }
// }

import { runStimulus } from './controllers/stimulus'
runStimulus()
